/* 
  We've already included normalize.css. 

  But we'd like a modern looking boilerplate. 
  Clean type, sans-serif, and a nice color palette. 
  
*/

* {
  box-sizing: border-box;
  color: inherit;
  margin: 0;
}

body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.2;
  color: #fff;
  background-color: #121314;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1.25;
  font-size: 14px;
  margin: 0;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}

li.message:hover {
  background-color: #f4f4f4;
}

a {
  text-decoration: none;
}

input {
  font-size: 16px;
}

#app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 700px) {
  body, input {
    font-size: 14px;
  }
}